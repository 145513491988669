<template>
  <nav class="navigation" :class="isOpen ? 'navigation--open' : ''" @click="clickOutside($event)">
    <div class="navigation__wrapper">
      <div class="navigation__close-icon" @click="close">
        <img src="@/assets//img/pictos/croix-menu.svg" alt="" />
      </div>

      <div class="navigation__section navigation__section--first">
        <p class="big-title">Menu</p>
        <p>Oui, oui, tout est là ! ♡</p>
      </div>

      <ul v-if="admin" class="navigation__section">
        <li class="navigation__item--title">
          <span>Admin</span>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'Profile', query: { limit: 50 } }" tag="a">
            Profil
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'dashboard' }" tag="a">
            Chiffres d'affaire
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'coupons-admin' }" tag="a">
            Coupons
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'carts-admin', query: { limit: 20 } }" tag="a">
            Paniers
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'shipping-admin', query: { limit: 50 } }" tag="a">
            Expéditions
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'product-baby-frame' }" tag="a">
            Cadres de naissance
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-vertical' }" tag="a">
            Cadre à poser vertical
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-horizontal' }" tag="a">
            Cadre à poser horizontal
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-horizontal-simple' }" tag="a">
            Cadre à poser horizontal simple
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-twins' }" tag="a">
            Cadre à poser spécial jumeaux
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-hanging-birth-frame-vertical' }" tag="a">
            Cadre à suspendre vertical
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-hanging-birth-frame-horizontal' }" tag="a">
            Cadre à suspendre horizontal
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'rainbow-birth-frame' }" tag="a">
            Cadre Arc-en-ciel
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'product-frame-baptism' }" tag="a">
            Cadres de baptême
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-baptism-ark' }" tag="a">
            Arche
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-baptism-classic' }" tag="a">
            Classique
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-frame-baptism-godfather-godmother' }" tag="a">
            Parrain marraine
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'creative-craft' }" tag="a">
            Matériel créatif
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'alphabet-stamps' }" tag="a">
            Sets de tampons alphabet
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'full-micro-set-dosis' }" tag="a">
            Sets de micro tampons alphabet
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'cookie-cutters' }" tag="a">
            Emporte-pièce
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'custom-cutters' }" tag="a">
            Emporte-pièce sur mesure
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'tourninouneur' }" tag="a">
            Outil de traçage de cercles
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'product-name' }" tag="a">
            Prénoms 3d
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-name-8-height' }" tag="a">
            8cm de haut
          </router-link>
        </li>
        <li class="navigation__item">
          <router-link :to="{ name: 'product-name-6-height' }" tag="a">
            6cm de haut
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'christmas-decorations' }" tag="a">
            Décorations de noël
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'starry-night' }" tag="a">
            "Boule" de Noël "Nuit Étoilée"
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'leaf-crown-christmas-ornament' }" tag="a">
            "Boule" de Noël couronne de feuilles
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'ark-snowflake' }" tag="a">
            "Arche" de Noël personnalisée
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'christmas-ornament' }" tag="a">
            "Boule" de Noël personnalisée
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'deer' }" tag="a">
            "Boule" de Noël Cerf
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'decorations' }" tag="a">
            Décorations
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-infinite' }" tag="a">
            Infini
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'deco-phrase' }" tag="a">
            Décoration "L'amour de ma vie"
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'cadre-de-mariage' }" tag="a">
            Cadre "Le mariage c'est"
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'hanging-frame' }" tag="a">
            Cadres à suspendre
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-flora-frame' }" tag="a">
            Cadre photo Flora
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-tipi-frame' }" tag="a">
            Cadre photo Tipi
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-hanging-birth-frame-vertical' }" tag="a">
            Cadre de naissance vertical
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-hanging-birth-frame-horizontal' }" tag="a">
            Cadre de naissance horizontal
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'custom-frame' }" tag="a">
            Cadres photo personnalisés
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'rainbow-frame-custom' }" tag="a">
            Cadre Arc-en-ciel
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'cadre-de-mariage' }" tag="a">
            Cadre de mariage
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'custom-vertical-frame' }" tag="a">
            Cadre à poser vertical
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'custom-horizontal-frame' }" tag="a">
            Cadre à poser horizontal
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'frame-phrase' }" tag="a">
            Cadre "L'amour de ma vie"
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'product-asking-gift' }" tag="a">
            Cadeaux de demande
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'asking-gift-godfather-vertical' }" tag="a">
            Cadre photo de demande parrain vertical
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'asking-gift-godmother-vertical' }" tag="a">
            Cadre photo de demande marraine vertical
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'asking-gift-godfather' }" tag="a">
            Cadre photo de demande parrain
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'asking-gift-godmother' }" tag="a">
            Cadre photo de demande marraine
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'product-gift-frame' }" tag="a">
            Cadeaux de remerciement
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-teacher-frame-gift' }" tag="a">
            Cadeau de remerciement enseignant
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-babysitter-frame-gift' }" tag="a">
            Cadeau de remerciement nounou
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link :to="{ name: 'product-aesh-frame-gift' }" tag="a">
            Cadeau de remerciement AESH
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'Checkout' }" tag="a">
            Mon panier
          </router-link>
        </li>
        <li class="navigation__item--title">
          <router-link :to="{ name: 'Profile' }" tag="a">
            Mon compte
          </router-link>
        </li>
      </ul>

      <ul class="navigation__section">
        <li class="navigation__item--title">
          <router-link :to="{ name: 'colors' }" tag="a">
            Guide des couleurs
          </router-link>
        </li>
        <li class="navigation__item--title">
          <router-link :to="{ name: 'faq' }" tag="a">
            Foire aux questions
          </router-link>
        </li>
        <li class="navigation__item--title">
          <router-link :to="{ name: 'cgu' }" tag="a">
            Conditions générales de vente
          </router-link>
        </li>
        <li class="navigation__item--title">
          <router-link :to="{ name: 'contact' }" tag="a">
            Contact
          </router-link>
        </li>
      </ul>

      <div class="socials">
        <nav class="socials__navigation">
          <a href="https://www.facebook.com/widddi3d" target="_blank">
            <img src="@/assets/img/pictos/facebook.svg" alt="Picto Facebook" />
          </a>
          <a href="https://www.instagram.com/widddi3d" target="_blank">
            <img src="@/assets/img/pictos/instagram.svg" alt="Picto Instagram" />
          </a>
          <a href="https://pinterest.fr/widddi3d" target="_blank">
            <img src="@/assets/img/pictos/pinterest.svg" alt="Picto Pinterest" />
          </a>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'WidddiNav',

  props: {
    isOpen: Boolean,
    admin: Boolean,
  },

  methods: {
    clickOutside(e) {
      const el = document.querySelector('.navigation__wrapper');

      if (el === e.target || el.contains(e.target)) {
        return;
      }

      this.close();
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
